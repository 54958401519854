import React from 'react'
import Layout from "../containers/layout";
import { graphql } from "gatsby"
import FilteredNewsOverview from "../containers/FilteredNewsOverview";

const NewsPage = ({data}) => {
    return (
        <Layout pageTitle={"News"}>
            <FilteredNewsOverview data={data.posts} className={'container'}/>
        </Layout>
    );
};

export default NewsPage

export const query = graphql`
    query NewsPostQuery {
        posts: allWordpressPost(
            filter: { status: {eq: "publish"} }
        ) {
            edges {
                node {
                    id
                    slug
                    title
                    excerpt
                    date
                    modified
                    template
                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 2048, grayscale: true) {
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    sizes
                                }
                            }
                        }
                    }
                    categories{
                        name
                    }
                }
            }
        }
    }
`;